import styled, { css, keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% {
    opacity: 1
  }
  50% {
   opacity: 0.4
  }
  100% {
    opacity: 1
  }
`;

interface SkeletonBlockProps {
  light?: boolean;
}

export const SkeletonBlockStyles = ({ light }: SkeletonBlockProps) => css`
  background-color: ${light ? `rgba(255, 255, 255, 0.6)` : `rgba(231, 231, 231, 0.6)`};
  animation: ${fadeInOut} 3s ease-in-out 0s infinite;
  border-radius: 5px;
`;

export const SkeletonBlock = styled.div<SkeletonBlockProps>`
  ${(props) => SkeletonBlockStyles(props)}
`;

interface SkeletonCircleProps {
  radius: number;
}

export const SkeletonCircle = styled(SkeletonBlock)<SkeletonCircleProps>`
  border-radius: 50%;
  ${({ radius }) => `
    width: ${radius}px;
    height: ${radius}px;
  `}
`;
